import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "meta-extra flex flex-col items-end pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, { class: "inbox-source-list" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_list, {
        "item-layout": "horizontal",
        "data-source": _ctx.appNotificationSource,
        split: false
      }, {
        renderItem: _withCtx(({ item }) => [
          _createVNode(_component_a_list_item, {
            class: _normalizeClass([
            _ctx.selectedPlatform === item.applicationNotificationId ? 'active' : '',
            'cursor-pointer',
          ]),
            onClick: ($event: any) => (_ctx.$emit('source-change', item.applicationNotificationId))
          }, {
            extra: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_badge, {
                  count: item.newMessageCount,
                  class: "w-min"
                }, null, 8, ["count"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_list_item_meta, {
                description: item?.latestNotification?.title
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_a_typography_paragraph, { class: "font-semibold mb-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                avatar: _withCtx(() => [
                  _createVNode(_component_PlatformIcon, {
                    class: "inbox-source-list__logo",
                    name: item?.name,
                    logo: item.logo,
                    size: 40,
                    "fallback-initial-icon": ""
                  }, null, 8, ["name", "logo"])
                ]),
                _: 2
              }, 1032, ["description"])
            ]),
            _: 2
          }, 1032, ["class", "onClick"])
        ]),
        _: 1
      }, 8, ["data-source"])
    ]),
    _: 1
  }))
}