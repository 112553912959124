
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    body: {
      type: String,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  components: {
    CommonCardLayout,
  },
});
