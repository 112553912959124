import gql from "graphql-tag";

export const updateNotificationGql = gql`
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      ... on NotificationResults {
        results {
          id
          created
          appNotification {
            id
            applicationClientId
            recipientStakingKeyHash
          }
          notificationType
          destination
          status
          title
          body
          media
          read
          isSpam
          purpose
          scheduled
          sent
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;

// separate query to mark as read, without fetching id so the cache won't be updated and cause infinite loading
export const markReadNotificationGql = gql`
  mutation UpdateNotificationMarkRead($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      ... on NotificationResults {
        results {
          read
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
