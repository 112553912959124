
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { formatShortTime } from "@/shared/utils/date";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { formatInboxDateRelative } from "@/web/utils/inboxUtils";
import { ApplicationNotificationSourceType } from "@/shared/composables/notification/useApplicationNotification";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";

export default defineComponent({
  components: { CommonCardLayout, PlatformIcon },
  props: {
    appNotificationSource: {
      type: Array as PropType<ApplicationNotificationSourceType>,
      required: true,
    },
    selectedPlatform: {
      type: String,
      required: true,
    },
  },
  emits: ["source-change"],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  methods: {
    formatShortTime,
    formatInboxDateRelative,
  },
});
