import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "confirm-inline mb-4 px-4 py-3 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_paragraph, { class: "flex-1" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.promptText), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_a_button, {
        size: "small",
        ghost: "",
        class: "mr-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        size: "small",
        ghost: "",
        class: "border-0",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
        ]),
        _: 1
      })
    ])
  ]))
}