import gql from "graphql-tag";

export const updateOauthSessionGql = gql`
  mutation UpdateOauthSession($input: UpdateOauthSessionInput!) {
    updateOauthSession(input: $input) {
      ... on Session {
        user {
          id
          username
          created
          modified
          wallet {
            id
            created
            modified
            stakingKeyHash
            mainAddress
          }
          name
          avatar40
          avatar80
          avatar400
          pendingAction
        }
        application {
          id
          clientId
          user {
            id
            username
            created
            modified
            wallet {
              id
              created
              modified
              stakingKeyHash
              mainAddress
            }
            name
            avatar40
            avatar80
            avatar400
            pendingAction
          }
          redirectUris
          name
          created
          updated
          logo40
          logo80
          logo400
          authorizedDomains {
            id
            value
            isVerified
            isPrimary
            isPublic
            verified
            verificationLastAttempt
            verificationAttemptCount
            created
            updated
          }
          includeSubdomain
          supportEmail {
            id
            created
            value
            isVerified
            isPrimary
            isPublic
            verified
            verificationCodeLastSent
            verificationLastAttempt
            verificationAttemptCount
            updated
          }
          pendingStep
          category
          isPublic
          homepageUri
          tosUri
          privacyPolicyUri
        }
        notificationChannel {
          id
          value
          isVerified
          isPrimary
          isPublic
          verified
          created
          updated
        }
        enableNotification
        allowedNotificationTypes
        isTerminated
        terminated
        lastVisited
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
