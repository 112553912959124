import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyList = _resolveComponent("EmptyList")!

  return (_openBlock(), _createBlock(_component_EmptyList, {
    title: _ctx.t('Your inbox is empty now.'),
    body: 
      _ctx.t(
        'There will be messages from services where you log in with {merchantBrand} account',
        {
          merchantBrand: _ctx.config.merchantBrand,
        }
      )
    
  }, null, 8, ["title", "body"]))
}