import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "empty-list__footer mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, { class: "empty-list text-center" }, {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_a_typography_title, {
            key: 0,
            level: 4,
            class: "empty-list__title text-center lighter font-normal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.body)
        ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
            key: 1,
            class: "empty-list__body text-center lighter m-auto"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.body), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.$slots['footer'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}