
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import isBoolean from "lodash/isBoolean";

export default defineComponent({
  props: {
    text: String,
  },
  setup() {
    const { t } = useI18n();
    const hiddenTextElement = ref();
    const hasReadMore = ref();
    const allShown = ref(false);

    watch(hiddenTextElement, (newHiddenTextElement) => {
      /**
       * the estimate line height of the text is 25
       * based on the calculated height if there should be a read more feature
       */
      if (
        newHiddenTextElement &&
        newHiddenTextElement.clientHeight &&
        newHiddenTextElement.clientHeight < 26
      ) {
        hasReadMore.value = false;
      } else {
        hasReadMore.value = true;
      }

      /**
       * Since the feature already been calculated
       * We can hide the hidden text element
       */
      if (newHiddenTextElement && isBoolean(hasReadMore.value)) {
        hiddenTextElement.value.style.display = "none";
      }
    });

    const toggleAllShown = () => {
      allShown.value = !allShown.value;
    };

    return { t, allShown, toggleAllShown, hasReadMore, hiddenTextElement };
  },
});
