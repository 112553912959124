
import { defineComponent, onMounted, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  components: {},
  props: {
    // if true, only emit visible once
    once: {
      type: Boolean,
      default: true,
    },
    // element's parent view
    rootId: {
      type: String,
      default: "",
    },
    // if disabled, do not listen for visibility change
    disabled: Boolean,
  },
  emits: ["on-visible"],
  setup(props, { emit }) {
    const id = ref(uuidv4());

    onMounted(() => {
      try {
        if (props.disabled) {
          return;
        }
        let options = {
          root: props.rootId
            ? document.querySelector(`#${props.rootId}`)
            : null,
          rootMargin: "0px",
          threshold: 1,
        };

        const targetElement = document.getElementById(id.value);

        let callback = (entries, observer) => {
          entries.forEach((entry) => {
            const isVisible = entry.intersectionRatio > 0.75;

            if (isVisible) {
              emit("on-visible");

              if (props.once) {
                observer.unobserve(targetElement);
              }
            }
          });
        };

        const observer = new IntersectionObserver(callback, options);

        if (targetElement) {
          observer.observe(targetElement);
        }
      } catch (error) {
        console.log(
          "Something went wrong when trying to inject IntersectionObserver",
          error
        );
      }
    });

    return {
      id,
    };
  },
});
