
import { i18nTranslate } from "@/plugins/i18n";
import ConfirmInline from "@/shared/components/ConfirmInline.vue";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { formatShortTime } from "@/shared/utils/date";
import { computed, defineComponent, PropType, ref } from "vue";
import isEmpty from "lodash/isEmpty";
import { useI18n } from "vue-i18n";
import backIconSrc from "@/assets/icons/back.svg";
import { ApplicationNotificationList_applicationNotificationList_AppNotificationList_results } from "@/api/notification/__generated__/ApplicationNotificationList";
import { ApplicationNotificationSourceItemType } from "@/shared/composables/notification/useApplicationNotification";
import TextSingleLineShowMore from "@/shared/components/TextSingleLineShowMore.vue";
import InboxMessagesListHeader from "@/web/views/Inbox/InboxMessages/InboxMessagesListHeader.vue";
import ObserverVisible from "@/shared/components/ObserverVisible.vue";

type SelectedPlatformUpdateType = "" | "unsubscribe" | "spam";

const platformUpdateLabelMap = {
  unsubscribe: i18nTranslate("Yes, unsubscribe"),
  spam: i18nTranslate("Yes, mark as spam"),
};

export default defineComponent({
  components: {
    CommonCardLayout,
    ConfirmInline,
    TextSingleLineShowMore,
    InboxMessagesListHeader,
    ObserverVisible,
  },
  props: {
    appNotificationListFromSource: {
      type: Array as PropType<
        Partial<ApplicationNotificationList_applicationNotificationList_AppNotificationList_results>[]
      >,
      required: true,
    },
    appNotificationSelectedSourceDetails: {
      type: Object as PropType<ApplicationNotificationSourceItemType>,
      required: true,
    },
    hasNextPage: Boolean,
  },
  emits: [
    "on-unsubscribe",
    "on-mark-spam",
    "on-mark-read",
    "on-unselect-inbox",
    "on-load-more",
  ],
  setup(props, { emit }) {
    const { t } = useI18n();
    const selectedPlatformUpdate = ref<SelectedPlatformUpdateType>("");

    const handleMenuSelect = (actionKey) => {
      console.log("handleMenuSelect", actionKey);
      selectedPlatformUpdate.value = actionKey;
    };

    const handleCancelUpdate = () => {
      selectedPlatformUpdate.value = "";
    };

    const handleConfirmUpdate = () => {
      const action = selectedPlatformUpdate.value;
      if (action === "unsubscribe") {
        emit(
          "on-unsubscribe",
          props?.appNotificationSelectedSourceDetails?.applicationNotificationId
        );
      } else if (action === "spam") {
        emit(
          "on-mark-spam",
          props?.appNotificationSelectedSourceDetails?.applicationNotificationId
        );
      }
      selectedPlatformUpdate.value = "";
    };

    return {
      t,
      backIconSrc,
      applicationName: computed(
        () => props.appNotificationSelectedSourceDetails?.name
      ),
      handleCancelUpdate,
      handleConfirmUpdate,
      handleMenuSelect,
      selectedPlatformUpdate,
      platformUpdateLabelMap,
    };
  },
  methods: {
    formatShortTime,
    isEmpty,
  },
});
