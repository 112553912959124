import { computed } from "vue";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  markReadNotificationGql,
  updateNotificationGql,
} from "@/api/notification/updateNotification";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { isEmpty } from "lodash";
import {
  UpdateNotification,
  UpdateNotificationVariables,
  UpdateNotification_updateNotification_NotificationResults,
} from "@/api/notification/__generated__/UpdateNotification";
import {
  UpdateNotificationMarkRead,
  UpdateNotificationMarkReadVariables,
  UpdateNotificationMarkRead_updateNotification_NotificationResults,
} from "@/api/notification/__generated__/UpdateNotificationMarkRead";
import { UpdateNotificationInput } from "__generated__/globalTypes";

export const useUpdateNotification = () => {
  const {
    mutate: updateNotificationMutate,
    loading: updateNotificationLoading,
  } = useCustomMutation<UpdateNotification, UpdateNotificationVariables>(
    updateNotificationGql
  );

  const updateNotification = async (input: UpdateNotificationInput) => {
    const updateNotificationResponse = await updateNotificationMutate({
      input,
    });
    const parsedResponse =
      parseGqlResponse<UpdateNotification_updateNotification_NotificationResults>(
        "NotificationResults",
        updateNotificationResponse
      );

    console.log("updateNotification:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updateNotificationResponse) {
      throw new Error("Failed to UpdateNotification");
    }

    return parsedResponse.data;
  };

  const { mutate: markAsReadMutate } = useCustomMutation<
    UpdateNotificationMarkRead,
    UpdateNotificationMarkReadVariables
  >(markReadNotificationGql);

  const markAsRead = async (
    notificationIdList: UpdateNotificationInput["notificationIdList"]
  ) => {
    const markAsReadResponse = await markAsReadMutate({
      input: {
        notificationIdList,
        markRead: true,
      },
    });
    const parsedResponse =
      parseGqlResponse<UpdateNotificationMarkRead_updateNotification_NotificationResults>(
        "NotificationResults",
        markAsReadResponse
      );

    console.log("markAsRead:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !markAsReadResponse) {
      console.log("Failed to mark as read");
    }

    return parsedResponse.data;
  };

  return {
    updateNotification,
    updateNotificationLoading,
    markAsRead,
    markAsReadLoading: computed(() => updateNotificationLoading.value),
  };
};
