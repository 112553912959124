
import { formatShortTime } from "@/shared/utils/date";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import InboxMessagesList from "./InboxMessagesList.vue";
import InboxMessagesSenders from "./InboxMessagesSenders.vue";
import {
  ApplicationNotificationSourceItemType,
  ApplicationNotificationSourceType,
} from "@/shared/composables/notification/useApplicationNotification";
import { ApplicationNotificationList_applicationNotificationList_AppNotificationList_results } from "@/api/notification/__generated__/ApplicationNotificationList";
import { useSessions } from "@/shared/composables/Sessions/useSessions";
import { message } from "ant-design-vue";

export default defineComponent({
  components: { InboxMessagesSenders, InboxMessagesList },
  props: {
    appNotificationSource: {
      type: Array as PropType<ApplicationNotificationSourceType>,
      required: true,
    },
    appNotificationSelectedSourceDetails: {
      type: Object as PropType<ApplicationNotificationSourceItemType>,
    },
    appNotificationListFromSource: {
      type: Array as PropType<
        Partial<ApplicationNotificationList_applicationNotificationList_AppNotificationList_results>[]
      >,
      required: true,
    },
    selectedPlatform: {
      type: String,
      default: "",
    },
    appNotificationListFromSourceLoading: Boolean,
    hasNextPage: Boolean,
    fetchMoreNotificationLoading: Boolean,
  },
  emits: ["on-load-more", "on-mark-read", "on-selected-app-change"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { handleOauthUpdateSession, updateOauthSessionLoading } =
      useSessions(false);

    const handleSourceChange = (senderId) => {
      emit("on-selected-app-change", senderId);
    };

    const handleMarkSpam = (platformId) => {
      console.log("handleMarkSpam:platformId", platformId);
    };

    const handleMarkRead = (notificationId) => {
      emit("on-mark-read", notificationId);
    };

    const handleUnsubscribe = async () => {
      try {
        const oauthSession =
          props?.appNotificationSelectedSourceDetails?.oauthSession;
        if (oauthSession && oauthSession?.id) {
          /**
           * Retain all details except enableNotification
           */
          await handleOauthUpdateSession({
            sessionId: oauthSession.id,
            allowedNotificationTypes: oauthSession?.allowedNotificationTypes,
            enableNotification: false,
          });

          message.info(
            t("You have been successfully unsubscribed from {app}", {
              app: oauthSession?.application?.name,
            })
          );
        }
      } catch (error) {
        console.log("InboxMessages.vue:handleUnsubscribe", error);
      }
    };

    return {
      t,
      handleSourceChange,
      handleMarkSpam,
      handleMarkRead,
      handleUnsubscribe,
      updateOauthSessionLoading,
    };
  },
  methods: {
    formatShortTime,
  },
});
