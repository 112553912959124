
import { i18nTranslate } from "@/plugins/i18n";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    promptText: {
      type: String,
      default: i18nTranslate("Are you sure?"),
    },
    confirmText: {
      type: String,
      default: i18nTranslate("Confirm"),
    },
    cancelText: {
      type: String,
      default: i18nTranslate("Cancel"),
    },
  },
  emits: ["confirm", "cancel"],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
  components: {},
});
