import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { oauthSessionsGql } from "@/api/sessions/oauthSessions";
import { OauthSessions_oauthSessions_OauthSessionResults } from "@/api/sessions/__generated__/OauthSessions";
import { updateOauthSessionGql } from "@/api/sessions/updateOauthSession";
import {
  UpdateOauthSession_updateOauthSession_Session,
  UpdateOauthSessionVariables,
  UpdateOauthSession,
} from "@/api/sessions/__generated__/UpdateOauthSession";
import { terminateOauthSessionGql } from "@/api/sessions/terminateOauthSession";
import {
  TerminateOauthSession_terminateOauthSession_GenericSuccess,
  TerminateOauthSessionVariables,
  TerminateOauthSession,
} from "@/api/sessions/__generated__/TerminateOauthSession";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import isEmpty from "lodash/isEmpty";
import { applicationNotificationDetailsGql } from "@/api/notification/applicationNotificationDetails";
import { UpdateOauthSessionInput } from "__generated__/globalTypes";
import { compactObject } from "@/shared/utils/object";

export const useSessions = (fetchApplicationOnMount = true) => {
  /**
   * GET OAUTH SESSIONS
   */
  const oauthSessionsQuery = useQuery(oauthSessionsGql, {}, () => ({
    enabled: fetchApplicationOnMount,
    fetchPolicy: "network-only",
  }));

  const oauthSessionsList = computed(() => {
    const parsedResponse =
      parseGqlResponse<OauthSessions_oauthSessions_OauthSessionResults>(
        "OauthSessionResults",
        oauthSessionsQuery.result.value
      );

    return parsedResponse.data?.results || [];
  });
  /**
   * END GET OAUTH SESSIONS
   */

  // REFETCH ALL OAUTH SESSIONS
  const handleRefetchOauthSessions = async () => {
    oauthSessionsQuery.refetch();
  };
  // END REFETCH ALL OAUTH SESSIONS

  // UPDATE SESSION
  const updateOauthSessionMutation = useCustomMutation<
    UpdateOauthSession,
    UpdateOauthSessionVariables
  >(updateOauthSessionGql, {
    refetchQueries: [
      { query: applicationNotificationDetailsGql },
      { query: oauthSessionsGql },
    ],
  });

  const handleOauthUpdateSession = ({
    sessionId,
    allowedNotificationTypes,
    enableNotification,
    notificationChannelId,
    dataChannelId,
  }: UpdateOauthSessionInput) => {
    return new Promise<UpdateOauthSession_updateOauthSession_Session | null>(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        const updateOauthSessionResponse =
          await updateOauthSessionMutation.mutate({
            input: {
              sessionId,
              allowedNotificationTypes,
              enableNotification,
              ...compactObject({ notificationChannelId, dataChannelId }),
            },
          });

        const parsedResponse =
          parseGqlResponse<UpdateOauthSession_updateOauthSession_Session>(
            "Session",
            updateOauthSessionResponse
          );

        console.log(
          "updateOauthSessionResponse:parsedResponse",
          parsedResponse
        );

        if (
          !isEmpty(parsedResponse.error?.errors) ||
          !updateOauthSessionResponse
        ) {
          return reject(parsedResponse.error?.errors);
        }

        return resolve(parsedResponse.data);
      }
    );
  };
  // END UPDATE SESSION

  // TERMINATE SESSION
  const terminateOauthSessionMutation = useCustomMutation<
    TerminateOauthSession,
    TerminateOauthSessionVariables
  >(terminateOauthSessionGql);

  const handleTerminateOauthSession = async ({ sessionId }) => {
    const terminateOauthSessionResponse =
      await terminateOauthSessionMutation.mutate({
        input: {
          sessionId,
        },
      });

    const parsedResponse =
      parseGqlResponse<TerminateOauthSession_terminateOauthSession_GenericSuccess>(
        "GenericSuccess",
        terminateOauthSessionResponse
      );

    console.log("terminateOauthSessionResponse:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !terminateOauthSessionResponse
    ) {
      throw new Error("Failed to terminate oauth session");
    }

    return parsedResponse.data;
  };
  // END TERMINATE SESSION

  return {
    oauthSessionsList,
    loading: computed(() => oauthSessionsQuery.loading.value),
    handleRefetchOauthSessions,
    handleOauthUpdateSession,
    updateOauthSessionLoading: computed(
      () => updateOauthSessionMutation.loading.value
    ),
    handleTerminateOauthSession,
    terminateOauthSessionLoading: computed(
      () => terminateOauthSessionMutation.loading.value
    ),
  };
};
