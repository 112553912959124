import gql from "graphql-tag";

export const applicationNotificationListGql = gql`
  query ApplicationNotificationList($id: ID!, $input: PaginationInput!) {
    applicationNotificationList(id: $id, input: $input) {
      ... on AppNotificationList {
        results {
          id
          appNotification {
            id
            applicationClientId
            recipientStakingKeyHash
          }
          notificationType
          destination
          status
          title
          body
          media
          read
          isSpam
          purpose
          sent
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
