import gql from "graphql-tag";

export const applicationNotificationDetailsGql = gql`
  query ApplicationNotificationDetails {
    applicationNotificationDetails {
      ... on AppNotificationDetailsResults {
        results {
          oauthSession {
            id
            application {
              ... on Application {
                id
                clientId
                name
                logo40
              }
            }
            enableNotification
            allowedNotificationTypes
            isTerminated
            terminated
            lastVisited
          }
          appNotification {
            applicationClientId
            recipientStakingKeyHash
            applicationNotificationId
            latestNotification {
              id
              title
              body
              created
            }
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
