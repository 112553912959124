import { dateFormats, formatShortTime } from "@/shared/utils/date";
import { format, isToday } from "date-fns";

export const formatInboxDateRelative = (date: Date) => {
  if (isToday(date)) {
    return formatShortTime(date);
  }

  return format(date, dateFormats.defaultDateWithNoTime);
};
