import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-single-line-show-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-single-line-show-more__text", [
        // if hasReadMore is disabled, make sure to unclamped the text
        _ctx.allShown || !_ctx.hasReadMore ? 'unclamped' : 'clamped',
        _ctx.$attrs.class,
      ]])
    }, _toDisplayString(_ctx.text), 3),
    (_ctx.hasReadMore)
      ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          type: "link",
          size: "small",
          onClick: _ctx.toggleAllShown,
          class: "p-0"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.allShown ? _ctx.t("Read less") : _ctx.t("Read more")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "hiddenTextElement",
      class: _normalizeClass(["text-single-line-show-more__text", _ctx.$attrs.class]),
      style: {"opacity":"0","position":"absolute"}
    }, _toDisplayString(_ctx.text), 3)
  ]))
}