import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "custom-menu" }
const _hoisted_2 = { class: "custom-menu__drawer-trigger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_dropdown, {
      trigger: ['click'],
      placement: "bottomRight",
      overlayClassName: "outlined",
      class: "custom-menu__dropdown"
    }, {
      overlay: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.options)
          ? (_openBlock(), _createBlock(_component_a_menu, {
              key: 0,
              selectable: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: option.key,
                    onClick: ($event: any) => (_ctx.handleOnMenuSelect(option.key))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "trigger", { open: _ctx.toggleDrawer })
      ]),
      _: 3
    }),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "trigger", { open: _ctx.toggleDrawer })
    ]),
    _createVNode(_component_a_drawer, {
      placement: "bottom",
      closable: "",
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      class: "custom-menu__drawer",
      height: "unset"
    }, _createSlots({
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.options)
          ? (_openBlock(), _createBlock(_component_a_menu, {
              key: 0,
              selectable: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: option.key,
                    onClick: ($event: any) => (_ctx.handleOnMenuSelect(option.key)),
                    class: "custom-menu__drawer-menu-item"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.$slots['footer'])
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "custom-menu__drawer-menu-footer py-4 px-6",
              onClick: _cache[0] || (_cache[0] = () => (_ctx.visible = false))
            }, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.title)
        ? {
            name: "title",
            fn: _withCtx(() => [
              _createVNode(_component_a_typography_title, {
                level: 4,
                class: "font-normal m-0 flex-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ])
          }
        : undefined
    ]), 1032, ["visible"])
  ]))
}