import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InboxMessagesSenders = _resolveComponent("InboxMessagesSenders")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InboxMessagesList = _resolveComponent("InboxMessagesList")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    class: "inbox-messages",
    type: "flex",
    gutter: { sm: 8, md: 16, lg: 32 }
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            class: _normalizeClass({
          'inbox-messages__sender-col': true,
          'inbox-messages__sender-col--selected': !!_ctx.selectedPlatform,
        })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InboxMessagesSenders, {
                "selected-platform": _ctx.selectedPlatform,
                "app-notification-source": _ctx.appNotificationSource,
                onSourceChange: _ctx.handleSourceChange
              }, null, 8, ["selected-platform", "app-notification-source", "onSourceChange"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.selectedPlatform)
            ? (_openBlock(), _createBlock(_component_a_col, {
                key: 0,
                id: "inbox-message-container",
                class: "inbox-messages__message-col inbox-messages__message-col-message-list",
                flex: "1"
              }, {
                default: _withCtx(() => [
                  (
            _ctx.appNotificationListFromSourceLoading &&
            !_ctx.fetchMoreNotificationLoading
          )
                    ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_InboxMessagesList, {
                        key: 1,
                        "selected-platform-id": _ctx.selectedPlatform,
                        "app-notification-list-from-source": _ctx.appNotificationListFromSource,
                        "app-notification-selected-source-details": 
            _ctx.appNotificationSelectedSourceDetails
          ,
                        "has-next-page": _ctx.hasNextPage && !_ctx.fetchMoreNotificationLoading,
                        onOnMarkSpam: _ctx.handleMarkSpam,
                        onOnUnsubscribe: _ctx.handleUnsubscribe,
                        onOnUnselectInbox: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSourceChange(''))),
                        onOnLoadMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-load-more'))),
                        onOnMarkRead: _ctx.handleMarkRead
                      }, null, 8, ["selected-platform-id", "app-notification-list-from-source", "app-notification-selected-source-details", "has-next-page", "onOnMarkSpam", "onOnUnsubscribe", "onOnMarkRead"])),
                  (_ctx.fetchMoreNotificationLoading)
                    ? (_openBlock(), _createBlock(_component_a_skeleton, {
                        key: 2,
                        title: false
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}