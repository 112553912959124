import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "inbox-messages-list" }
const _hoisted_2 = { class: "message-card__body text-center py-8" }
const _hoisted_3 = { class: "message-card__body" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "message-card__body-media-container" }
const _hoisted_7 = { class: "text-center mt-8 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmInline = _resolveComponent("ConfirmInline")!
  const _component_InboxMessagesListHeader = _resolveComponent("InboxMessagesListHeader")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!
  const _component_TextSingleLineShowMore = _resolveComponent("TextSingleLineShowMore")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ObserverVisible = _resolveComponent("ObserverVisible")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.selectedPlatformUpdate)
          ? (_openBlock(), _createBlock(_component_ConfirmInline, {
              key: _ctx.selectedPlatformUpdate,
              "confirm-text": _ctx.platformUpdateLabelMap[_ctx.selectedPlatformUpdate],
              onConfirm: _ctx.handleConfirmUpdate,
              onCancel: _ctx.handleCancelUpdate
            }, null, 8, ["confirm-text", "onConfirm", "onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, null, {
      default: _withCtx(() => [
        (_ctx.isEmpty(_ctx.appNotificationListFromSource))
          ? (_openBlock(), _createBlock(_component_CommonCardLayout, {
              key: 0,
              class: "message-card"
            }, {
              title: _withCtx(() => [
                _createVNode(_component_InboxMessagesListHeader, {
                  "application-name": _ctx.applicationName,
                  onOnUnselectInbox: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-unselect-inbox'))),
                  onOnMenuSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMenuSelect($event)))
                }, null, 8, ["application-name"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    class: "lighter mb-4"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("No messages")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, { class: "lighter m-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("There will be messages from the service")), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.appNotificationListFromSource, (msg, msgIndex) => {
              return (_openBlock(), _createBlock(_component_CommonCardLayout, {
                key: msg.id,
                class: "mb-4 message-card"
              }, _createSlots({
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ObserverVisible, {
                      "root-id": "inbox-message-container",
                      disabled: !!msg.read,
                      onOnVisible: ($event: any) => (_ctx.$emit('on-mark-read', msg.id))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_a_typography_paragraph, { class: "font-bold m-0 flex-1" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(msg.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (!!msg.sent)
                            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                                key: 0,
                                class: "message-card__body-date small ml-6 lighter"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatShortTime(msg.sent)), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_TextSingleLineShowMore, {
                            text: msg.body,
                            class: "message-card__body-text"
                          }, null, 8, ["text"])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(msg.media, (imgSrc, mediaIndex) => {
                            return (_openBlock(), _createBlock(_component_a_image, {
                              key: `${_ctx.applicationName}-${msgIndex}-media-${mediaIndex}`,
                              class: "my-2 message-card__body-media",
                              src: imgSrc
                            }, null, 8, ["src"]))
                          }), 128))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onOnVisible"])
                  ])
                ]),
                _: 2
              }, [
                (msgIndex === 0 && _ctx.applicationName)
                  ? {
                      name: "title",
                      fn: _withCtx(() => [
                        _createVNode(_component_InboxMessagesListHeader, {
                          "application-name": _ctx.applicationName,
                          onOnUnselectInbox: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-unselect-inbox'))),
                          onOnMenuSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleMenuSelect($event)))
                        }, null, 8, ["application-name"])
                      ])
                    }
                  : undefined
              ]), 1024))
            }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.hasNextPage)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            size: "small",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-load-more')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}