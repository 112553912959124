import { computed, reactive, Ref, ref } from "vue";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { applicationNotificationDetailsGql } from "@/api/notification/applicationNotificationDetails";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import {
  ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults,
  ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults_results_appNotification_latestNotification,
  ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults_results_oauthSession,
} from "@/api/notification/__generated__/ApplicationNotificationDetails";
import { applicationNotificationListGql } from "@/api/notification/applicationNotificationList";
import {
  ApplicationNotificationList,
  ApplicationNotificationListVariables,
  ApplicationNotificationList_applicationNotificationList_AppNotificationList,
  ApplicationNotificationList_applicationNotificationList_AppNotificationList_results,
} from "@/api/notification/__generated__/ApplicationNotificationList";

/**
 * This has to be created because the source (AppNotificationDetailsResults_results) is too deep and partial of both union is required
 */
export type ApplicationNotificationSourceItemType = {
  applicationNotificationId: string;
  name?: string;
  logo?: string | null;
  oauthSession?: Partial<ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults_results_oauthSession> | null;
  latestNotification?: Partial<ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults_results_appNotification_latestNotification>;
};

export type ApplicationNotificationSourceType =
  ApplicationNotificationSourceItemType[];

type ApplicationList = {
  id: string;
  list: Partial<ApplicationNotificationList_applicationNotificationList_AppNotificationList_results>[];
};

export const useApplicationNotification = (selectedAppId: Ref<string>) => {
  /**
   * APPLICATION NOTIFICATION SOURCE
   * This is the list of application notification source or the sender list
   */
  const {
    result: applicationNotificationDetailsResult,
    loading: appNotificationSourceLoading,
  } = useQuery(applicationNotificationDetailsGql, {}, () => ({
    fetchPolicy: "network-only",
  }));

  const applicationNotificationDetailsParsedData = computed(() => {
    const parsedResponse =
      parseGqlResponse<ApplicationNotificationDetails_applicationNotificationDetails_AppNotificationDetailsResults>(
        "AppNotificationDetailsResults",
        applicationNotificationDetailsResult.value
      );

    return parsedResponse.data;
  });
  const appNotificationSource = computed<ApplicationNotificationSourceType>(
    () => {
      return (
        applicationNotificationDetailsParsedData.value?.results?.map(
          (item) => ({
            applicationNotificationId: item?.appNotification
              ?.applicationNotificationId as string, // this will be used to retrieve application notification list
            name: item?.oauthSession?.application?.name, // this is the application name
            logo: item?.oauthSession?.application?.logo40, // this is the application logo
            oauthSession: item?.oauthSession,
            latestNotification: {
              ...(item?.appNotification?.latestNotification ?? {}),
            },
          })
        ) ?? []
      );
    }
  );
  /**
   * Selected notification source details
   */
  const appNotificationSelectedSourceDetails = computed(() => {
    return find(
      appNotificationSource.value,
      (item) => item.applicationNotificationId === selectedAppId.value
    );
  });
  /**
   * END APPLICATION NOTIFICATION SOURCE
   */

  /**
   * APPLICATION NOTIFICATION LIST
   * This is the list of message depending on the selected notification source
   */
  const appNotificationList = reactive<ApplicationList>({
    id: selectedAppId.value,
    list: [],
  });
  const {
    result: applicationNotificationListResult,
    loading: appNotificationListFromSourceLoading,
    refetch: appNotificationListRefetch,
    onResult: applicationNotificationListOnResult,
  } = useQuery<
    ApplicationNotificationList,
    ApplicationNotificationListVariables
  >(
    applicationNotificationListGql,
    () => ({
      id: selectedAppId.value,
      input: {
        after: null,
        pageSize: 5,
      },
    }),
    () => ({
      enabled: !!selectedAppId.value,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    })
  );

  /**
   * Handle Fetch more with loading indicator and variable values selected automatically
   */
  const fetchMoreNotificationLoading = ref(false);
  const fetchMoreNotification = async () => {
    // Only proceed if has next page and theres an end cursor
    if (
      appNotificationListFromSourcePageInfo.value?.hasNextPage &&
      appNotificationListFromSourcePageInfo.value.endCursor
    ) {
      // set loading, the refetch method will not be resolved if not yet done with the request
      fetchMoreNotificationLoading.value = true;

      /**
       * Use updated variable inputs
       */
      await appNotificationListRefetch({
        id: selectedAppId.value,
        input: {
          after: appNotificationListFromSourcePageInfo.value.endCursor,
          pageSize: 5,
        },
      });

      // reset loading
      fetchMoreNotificationLoading.value = false;
    }
  };

  /**
   * This method is a hook of the query and will be called automatically on result, do not export this
   */
  applicationNotificationListOnResult((result) => {
    const parsedData =
      parseGqlResponse<ApplicationNotificationList_applicationNotificationList_AppNotificationList>(
        "AppNotificationList",
        result.data
      );

    /**
     * If a new app source is selected update the Id and clear the list
     */
    if (selectedAppId.value != appNotificationList.id) {
      appNotificationList.id = selectedAppId.value;
      appNotificationList.list = [];
    }

    if (parsedData?.data && !isEmpty(parsedData?.data.results)) {
      parsedData?.data?.results?.forEach((result) => {
        const notificationIndex = appNotificationList.list.findIndex(
          (item) => item?.id === result?.id
        );

        if (result && notificationIndex < 0) {
          // only add item if doesn't already exist yet on the list
          appNotificationList.list.push(result);
        } else {
          // update the data with new one
          appNotificationList.list[notificationIndex] = {
            ...(appNotificationList.list[notificationIndex] ?? {}),
            ...(result ?? {}),
          };
        }
      });
    }
  });

  const appNotificationListParsedData = computed(() => {
    return parseGqlResponse<ApplicationNotificationList_applicationNotificationList_AppNotificationList>(
      "AppNotificationList",
      applicationNotificationListResult.value
    ).data;
  });

  const appNotificationListFromSourcePageInfo = computed(
    () => appNotificationListParsedData.value?.pageInfo
  );

  const appNotificationListFromSource = computed(
    () => appNotificationList.list
  );

  /**
   * END APPLICATION NOTIFICATION LIST
   */

  return {
    selectedAppId,
    /**
     * Message Source
     */
    appNotificationSource,
    appNotificationSourceLoading,

    /**
     * Messages from the selected source
     */
    appNotificationListRefetch,
    appNotificationListFromSource,
    appNotificationListFromSourcePageInfo,
    appNotificationListFromSourceLoading,
    appNotificationSelectedSourceDetails,
    fetchMoreNotification,
    fetchMoreNotificationLoading,
  };
};
