
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import EmptyList from "@/shared/components/Layouts/EmptyList.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    return {
      t,
      config,
    };
  },
  components: { EmptyList },
});
