import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InboxEmpty = _resolveComponent("InboxEmpty")!
  const _component_InboxMessages = _resolveComponent("InboxMessages")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Inbox')
  }, {
    default: _withCtx(() => [
      (_ctx.appNotificationSourceLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.inboxEmpty)
              ? (_openBlock(), _createBlock(_component_InboxEmpty, { key: 0 }))
              : (_openBlock(), _createBlock(_component_InboxMessages, {
                  key: 1,
                  "selected-platform": _ctx.selectedAppId,
                  "app-notification-source": _ctx.appNotificationSource,
                  "app-notification-selected-source-details": 
          _ctx.appNotificationSelectedSourceDetails
        ,
                  "app-notification-list-from-source": _ctx.appNotificationListFromSource,
                  "app-notification-list-from-source-loading": 
          _ctx.appNotificationListFromSourceLoading
        ,
                  "fetch-more-notification-loading": _ctx.fetchMoreNotificationLoading,
                  "has-next-page": !!_ctx.appNotificationListFromSourcePageInfo?.hasNextPage,
                  onOnLoadMore: _ctx.fetchMoreNotification,
                  onOnMarkRead: _ctx.handleMarkRead,
                  onOnSelectedAppChange: _ctx.handleSelectedAppChange
                }, null, 8, ["selected-platform", "app-notification-source", "app-notification-selected-source-details", "app-notification-list-from-source", "app-notification-list-from-source-loading", "fetch-more-notification-loading", "has-next-page", "onOnLoadMore", "onOnMarkRead", "onOnSelectedAppChange"]))
          ], 64))
    ]),
    _: 1
  }, 8, ["title"]))
}