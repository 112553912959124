
import { computed, defineComponent, ref } from "vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import { config } from "@/shared/utils/config";
import InboxEmpty from "@/web/views/Inbox/InboxEmpty.vue";
import InboxMessages from "@/web/views/Inbox/InboxMessages/InboxMessages.vue";
// import groupBy from "lodash/groupBy";
// import { formatDate, dateFromNowPretty } from "@/shared/utils/date";
import { useApplicationNotification } from "@/shared/composables/notification/useApplicationNotification";
import { useUpdateNotification } from "@/shared/composables/notification/useUpdateNotification";

export default defineComponent({
  components: { PageLayout, InboxEmpty, InboxMessages },
  setup() {
    const { t } = useI18n();
    const selectedAppId = ref("");

    const {
      appNotificationSource,
      appNotificationSourceLoading,
      appNotificationListFromSource,
      appNotificationListFromSourceLoading,
      appNotificationSelectedSourceDetails,
      appNotificationListFromSourcePageInfo,
      fetchMoreNotification,
      fetchMoreNotificationLoading,
    } = useApplicationNotification(selectedAppId);

    const { markAsRead } = useUpdateNotification();

    const handleMarkRead = (notificationId) => {
      console.log("handleMarkRead:notificationId", notificationId);
      if (notificationId) {
        markAsRead([notificationId]);
      }
    };

    const handleSelectedAppChange = (newSelected) => {
      selectedAppId.value = newSelected;
    };

    // TODO: handle list by date
    // const grouped = groupBy(firstCollection, (message) =>
    //   formatDate(message.date.toISOString())
    // );

    return {
      t,
      selectedAppId,
      config,
      inboxEmpty: computed(() => isEmpty(appNotificationSource.value)),
      appNotificationSource,
      appNotificationSourceLoading,
      appNotificationListFromSource,
      appNotificationListFromSourceLoading,
      appNotificationSelectedSourceDetails,
      appNotificationListFromSourcePageInfo,
      fetchMoreNotification,
      fetchMoreNotificationLoading,
      handleMarkRead,
      handleSelectedAppChange,
    };
  },
});
