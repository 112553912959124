
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import backIconSrc from "@/assets/icons/back.svg";
import CustomMenu from "@/shared/components/CustomMenu.vue";
import { i18nTranslate } from "@/plugins/i18n";

const inboxMenuOptions = [
  // temporarily remove: see https://app.clickup.com/t/3c7dzn7
  // {
  //   key: "spam",
  //   title: i18nTranslate("Mark as spam"),
  // },
  {
    key: "unsubscribe",
    title: i18nTranslate("Unsubscribe"),
  },
];

export default defineComponent({
  components: {
    CustomMenu,
  },
  props: {
    applicationName: {
      type: String,
      default: "",
    },
  },
  emits: ["on-unselect-inbox", "on-menu-select"],
  setup() {
    const { t } = useI18n();

    return { t, backIconSrc, inboxMenuOptions };
  },
  methods: {},
});
