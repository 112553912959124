
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";

export type MenuOption = {
  key: string;
  title: string;
};

/**
 * This custom menu will work as pop-up dropdown on desktop and as drawer on mobile
 */
export default defineComponent({
  emits: ["on-select"],
  props: {
    title: {
      type: String,
    },
    options: {
      type: Array as PropType<MenuOption[]>,
    },
  },
  setup(_props, { emit }) {
    const { t } = useI18n();
    const visible = ref(false);

    const toggleDrawer = () => {
      visible.value = !visible.value;
    };

    const handleOnMenuSelect = (selectedOption) => {
      emit("on-select", selectedOption);
      visible.value = false;
    };

    return {
      t,
      visible,
      handleOnMenuSelect,
      toggleDrawer,
    };
  },
  methods: {},
  components: {},
});
