import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inbox-messages-list-header flex items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_EllipsisOutlined = _resolveComponent("EllipsisOutlined")!
  const _component_CustomMenu = _resolveComponent("CustomMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_button, {
      type: "text",
      class: "inbox-messages-list-header__back-button p-0",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-unselect-inbox')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.backIconSrc,
          alt: "back",
          class: "mr-2",
          height: "20"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, {
      level: 4,
      class: "font-normal m-0 flex-1"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.applicationName), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_CustomMenu, {
      title: 
        _ctx.t('{platform} actions', {
          platform: _ctx.applicationName,
        })
      ,
      options: _ctx.inboxMenuOptions,
      onOnSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-menu-select', $event)))
    }, {
      trigger: _withCtx(({ open }) => [
        _createVNode(_component_EllipsisOutlined, {
          style: {"font-size":"1.75rem"},
          class: "opacity-60",
          onClick: _withModifiers(open, ["prevent"])
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "options"])
  ]))
}